<!-- Footer Start -->
<div class="container-fluid bg-primary text-light footer mt-5 pt-5 wow fadeIn" data-wow-delay="0.1s">
  <div class="container py-5 px-lg-5">
    <div class="row g-5">
      <div class="col-md-6 col-lg-4">
        <h5 class="text-white mb-4">Contact</h5>
        <p><i class="fa fa-map-marker-alt me-3"></i>Rennes, France </p>
        <p><i class="fa fa-phone-alt me-3"></i>(+33) 7 62 54 09 78</p>
        <p><i class="fa fa-envelope me-3"></i>webcome.co&#64;outlook.fr</p>
        <div class="d-flex pt-2">
          <a class="btn btn-outline-light btn-social" href="https://www.instagram.com/webcomeco">
            <i class="fab fa-instagram"></i>
          </a>
          <a class="btn btn-outline-light btn-social"
             href="https://www.linkedin.com/company/webcomeandco/about/?viewAsMember=true">
            <i class="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <h5 class="text-white mb-4">Mentions Légales</h5>
        <a class="btn btn-link" (click)="handlePage('/about')">A Propos De Nous</a>
        <a class="btn btn-link" (click)="handlePage('/contact')">Contactez-Nous</a>
        <a class="btn btn-link" (click)="handlePage('/mentionsLegals')">Mentions Légales</a>
        <a class="btn btn-link" (click)="handlePage('/termsConditions')">Termes & Conditions</a>
      </div>
      <div class="col-md-6 col-lg-4">
        <h5 class="text-white mb-4">Guides</h5>
        <a class="btn btn-link" (click)="handlePage('/referencement-seo')">Qu'est-ce que le SEO</a>
        <a class="btn btn-link" (click)="handlePage('/referencement-sea')">Qu'est-ce que le SEA</a>
        <a class="btn btn-link" (click)="handlePage('/creation-site-web')">Création De Site Internet</a>
      </div>
    </div>
  </div>
  <div class="container px-lg-5">
    <div class="copyright">
      <div class="row">
        <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">Copyright&copy;2024</div>
        <div class="col-md-6 text-center text-md-end">
          <div class="footer-menu">
            <span> Réalisé par Agence  <a href="">WEBCOME & CO</a></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->
