<div class="hero-header">
  <div class="text-center my-5 px-lg-5">
    <h3 class="text-dark animated zoomIn">
      <i class="fa fa-rocket fa-1x text-secondary"></i> &ensp; Agence de Marketing Digital
      <br> <i class="fa fa-globe fa-1x text-secondary"></i> &ensp; Création de sites web & stratégie digitale.
      <br> <i class="fa fa-angle-double-up fa-1x text-secondary"></i> &ensp; Boostez vos ventes et votre présence en ligne !
    </h3>
    <a class="btn bg-primary text-white py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft"
       (click)="handleContactPage()">Contactez-nous !</a>
    <a class="btn bg-primary text-white py-sm-3 px-sm-5 rounded-pill animated slideInRight"
       (click)="handleAboutPage()">Nous découvrir</a>
  </div>
</div>


<div class="container-xxl py-5 mb-4">
  <div class="container px-lg-5">
    <div class="row g-5">
      <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
        <div class="section-title position-relative mb-4 pb-2">
          <h6 class="position-relative text-primary ps-4"> Nous découvrir</h6>
          <h2 class="mt-2">Webcome & Co : Votre Partenaire en Performance Digital</h2>
        </div>
        <p class="mb-4">
          <span class="fw-bold">Vous cherchez à booster votre visibilité en ligne ?</span>
          <br> Webcome & Co est l’agence qu’il vous faut. Experts en création de sites web et stratégies digitales, nous
          développons des solutions
          sur-mesure pour maximiser vos résultats</p>
        <span class="fw-bold"> Pourquoi Choisir Webcome & Co ? </span>
        <ul>
          <li> Sites web performants : Conçus pour attirer, engager et convertir.</li>
          <li> Stratégies axées sur les résultats : Plus de trafic, plus de leads, plus de ventes.</li>
          <li> Expertise reconnue : SEO, campagnes publicitaires, gestion de contenu, tout y est.</li>
        </ul>
        <div class="fw-bold mb-2 pb-2"> Avec nous, vous obtenez des résultats rapides et mesurables. Ne laissez plus
          votre croissance au hasard :
          contactez Webcome & Co et passez à la vitesse supérieure.
        </div>
        <div class="row g-3">
          <div class="col-sm-6">
            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>Référencement</h6>
            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Transparence et communication</h6>
          </div>
          <div class="col-sm-6">
            <h6 class="mb-3"><i class="fa fa-check text-primary me-2"></i>24/7 Support</h6>
            <h6 class="mb-0"><i class="fa fa-check text-primary me-2"></i>Expérience éprouvée</h6>
          </div>
        </div>
        <div class="d-flex align-items-center mt-4">
          <a class="btn btn-primary rounded-pill px-4 me-3" href="/about">En Savoir plus</a>
          <a class="btn btn-outline-primary btn-square me-3"
             href="https://www.instagram.com/webcomeco/">
            <i class="fab fa-instagram"></i></a>
          <a class="btn btn-outline-primary btn-square"
             href="https://www.linkedin.com/company/webcomeandco/about/?viewAsMember=true">
            <i class="fab fa-linkedin-in"></i></a>
        </div>
      </div>
      <div class="col-lg-6">
        <img class="img-fluid wow zoomIn mt-5" data-wow-delay="0.5s" ngSrc="assets/img/about-img.png" height="2001"
             width="2101" [priority]="true" alt="">
      </div>
    </div>
  </div>
</div>

<app-service
  [sectionTitleService]="'Nos Services'"
  [services]="services">
</app-service>


<div class="container-xxl py-5">
  <div class="container px-lg-5">
    <div class="section-title position-relative text-center mb-2 pb-2 wow fadeInUp" data-wow-delay="0.1s">
      <h3 class="position-relative d-inline text-primary ps-4"> Notre Méthodologie </h3>
    </div>

    <div class="section-step">
      <img ngSrc="assets/img/test-1.png" class="responsive-image" height="720" width="1280" alt="img" loading="lazy">
    </div>
  </div>
</div>

<app-counters class="m-2"></app-counters>

<div class="bar-title">
  <div class="wow fadeInUp" data-wow-delay="0.1s">
    <h1 class="text-center text-white m-3"> Profitez de cette opportunité unique pour booster votre <br> business !
    </h1>
  </div>
  <div class="wow fadeInUp">
    <a class="btn bg-primary text-white py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft m-3"
       (click)="handleContactPage()">Lançons ensemble votre réussite numérique </a>
  </div>
</div>

<div class="container-xl pt-5">
  <div class="position-relative text-center wow fadeInUp" data-wow-delay="0.1s">
    <h1 class="text-white"><span class="btn-color-secondary">Une audit gratuit de 30 minutes !</span></h1>
  </div>
  <app-calendly-widget></app-calendly-widget>
</div>

<app-team [teamsItem]="team"></app-team>
