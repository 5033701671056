import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-guide-sea',
  standalone: true,
  imports: [],
  templateUrl: './guide-sea.component.html',
  styleUrl: './guide-sea.component.css'
})
export class GuideSeaComponent {

}
