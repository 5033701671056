<div class="container-xxl py-2">
  <div class="container px-lg-5">
    <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
      <h3 class="position-relative d-inline text-primary ps-4">Nos chiffres clés</h3>
    </div>

    <div class="counters">
      <!-- Counter 1 -->
      <div class="counter customer">
        <h2 class="number"><span class="value" data-value="95">0</span>%</h2>
        <p class="detail">Satisfaction client</p>
      </div>
      <!-- Counter 2 -->
      <div class="counter project">
        <h2 class="number"><span class="value" data-value="99">0</span>%</h2>
        <p class="detail">Projets livrés dans les délais</p>
      </div>
      <!-- Counter 4 -->
      <div class="counter award">
        <h2 class="number">+<span class="value" data-value="65">0</span>%</h2>
        <p class="detail">du CA depuis 2023</p>
      </div>
      <!-- Counter 3 -->
      <div class="counter employee">
        <h2 class="number"><span class="value" data-value="24">0</span>/7</h2>
        <p class="detail">Support techniques et stratégiques</p>
      </div>
    </div>
  </div>
</div>
