<div class="py-5 btn-primary mb-5">
  <div class="container my-5 py-5 px-lg-5">
    <div class="col-12">
      <h1 class="text-white animated zoomIn"><span>Création de site internet</span></h1>
      <p class="text-center animated zoomIn">
        Vous cherchez à créer votre propre site, à développer le site de votre entreprise ou simplement d’élargir vos
        compétences en création de site internet ? En tant qu’agence de création de site, WebCome & Co propose un guide
        complet afin d’aborder toutes les étapes et les composantes d’un projet de création ou de refonte de site. Créer
        un site web est un processus complexe qui nécessite une réflexion stratégique, une compréhension du
        référencement naturel et du webmarketing, une réflexion sur l’expérience utilisateur et le design, et bien plus
        encore.</p>
      <p class="text-center animated zoomIn">
        Novice ou initié, vous trouverez ici des conseils pour choisir votre CMS, définir votre nom de domaine, choisir
        un hébergement web et bien plus. Nous aborderons également des aspects moins connus, mais tout aussi importants
        de la création de sites internet, tels que les coûts associés, les critères de choix de votre CMS (WordPress,
        Prestashop…), les différentes options pour la réalisation d’un site (en interne, freelance, agence web), ainsi
        que ce qui se passe après la mise en ligne de votre site. Prêt à en savoir plus sur la création de site ?
      </p>
    </div>
  </div>
</div>


<div class="container-xxl py-5">
  <div class="container px-lg-5">
    <div class="flex-column justify-content-center">
      <h1 class="text-center animated zoomIn m-2"><span>Les différents types de site internet</span></h1>
      <div class="row g-4 d-flex justify-content-center">
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center  rounded">
            <span class="btn-color-secondary text-center fw-bold"> Site Vitrine </span>
            <p class="text-center">C’est le format le plus courant pour les petites entreprises et les indépendants. Un
              site vitrine permet de présenter votre activité, vos services, votre équipe et d’offrir un moyen de
              contact à vos visiteurs. Il se concentre généralement sur l’information et l’image de marque.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center rounded">
            <span class="btn-color-secondary text-center fw-bold">Site e-commerce</span>
            <p class="text-center">Si vous souhaitez vendre des produits ou des services en ligne, un site e-commerce
              est le choix approprié. Il comprend des fonctionnalités comme le panier d’achat, le paiement en ligne, et
              la gestion des stocks.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center rounded">
            <span class="btn-color-secondary text-center fw-bold"> Blog  </span>
            <p class="text-center"> Ce type de site est axé sur la production régulière de contenu, généralement sous
              forme d’articles. Il peut être utilisé comme un outil de marketing de contenu pour améliorer le
              référencement naturel de votre site.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center rounded">
            <span class="btn-color-secondary text-center fw-bold"> Site de contenu </span>
            <p class="text-center"> Il peut s’agir d’un site d’information, d’un magazine en ligne, d’un site éducatif
              ou d’un wiki. Ces sites sont souvent volumineux et contiennent une grande quantité de contenu textuel.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

