<div class="py-5 btn-primary mb-5">
  <div class="container my-5 py-5 px-lg-5">
    <div class="align-items-center">
      <div class="col-12 text-center">
        <h1 class="text-white animated zoomIn mt-5">Webcome & Co <br> Développe <span class="btn-color-secondary"> Le Succès de vos projets ! </span>
        </h1>
        <p class="mt-5 text-center animated zoomIn"></p>
      </div>
    </div>
  </div>
</div>
<app-service
  [sectionTitleService]="'Nos expertises'"
  [services]="services">
</app-service>
