<div class="py-5 btn-primary mb-5">
  <div class="container my-5 py-5 px-lg-5">
    <div class="align-items-center">
      <div class="col-12 text-center text-white text-white ">
        <h1 class="animated zoomIn mt-5">Discutons ensemble de votre projet !
        </h1>
        <p class="mt-5 text-center animated zoomIn">Nos équipes sont disponibles pour échanger avec vous, vous conseiller et vous aider à atteindre vos objectifs !
          Ensemble, créons un projet qui vous ressemble !</p>
      </div>
    </div>
  </div>
</div>

<div class="container">
  <div class="row">
    <div class="col-6 animated zoomIn">
      <h1 class="text-white"><span class="btn-color-secondary">Prenez rendez-vous facilement</span>
      </h1>
      <p class="mt-5"> Webcome & Co vous accompagne dans chaque étape de votre projet, et s’adapte au
        niveau d’expertise web que vous possédez déjà (ou non!) en interne. Nos process ont pour objectif de vous
        permettre d’être informé de chaque étape du projet, et de pouvoir valider au fur et à mesure les
        développements et les améliorations qu'on peut apporter à votre projet.
        Au cours de ce rendez-vous, nous allons :</p>
      <ul cla>
        <li class="m-2">Échanger avec vous sur votre projet : comprendre les objectifs, les ambitions et les attentes
          dans un premier temps; pour ensuite entrer dans le détail de vos attentes fonctionnelles
        </li>
        <li class="m-2">Ces éléments nous permettrons de préconiser une solution technique, et surtout d'élaborer une
          proposition avec nos équipes
        </li>
      </ul>

      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">
              <i class="fa fa-hand-holding-usd fa-1x m-lg-2"></i>
              Travaillez-vous avec des budgets minimums / maximums ?
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              Nous écoutons chacun de vos projets avec une oreille attentive afin de vous budgétiser la meilleure
              solution pour votre activité.
              Nous n’avons pas de budget minimum car nous vous proposons différents types de prestations (stratégie
              digitale, graphisme, développement web, maintenance).
              En revanche, nous vous demanderons lors de notre discussion quelle enveloppe budgétaire vous avez en tête
              afin de tout de suite vous dire si, avec le projet que vous avez en face, c’est réalisable ou non.
              L’idée est de ne pas avoir de mauvaise surprise !
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              <i class="fa fa-edit fa-1x m-lg-2"></i>
              Je n'ai pas de charte graphique ou de logo, pouvez-vous travailler dessus ?
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              Avec grand plaisir ! Nos équipes de graphistes sont formés pour cela et interviennent sur tous types de
              secteur d’activité.
              Chaque entreprise à son histoire et sa personnalité. Nous les ferons transparaitre via votre nouveau logo
              pour vous créer une identité unique.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              <i class="fa fa-home fa-1x m-lg-2"></i>
              Une fois le site terminé, est-ce que je serais le propriétaire ?
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              Chez WebCome & Co, vous serez l’unique propriétaire de votre site ainsi que des éléments rattachés :
              hébergement , nom de domaine, éléments graphiques, visuels…. Pas de système d’abonnement vous êtes donc
              libre de partir ou de rester avec nous !
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapsefoor">
              <i class="fa fa-chart-line fa-1x m-lg-2"></i>
              Pouvez-vous garantir que mon site internet se retrouvera en première position sur Google ?
            </button>
          </h2>
          <div id="collapseFoor" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              Tout comme vous, nous souhaiterions avoir ce pouvoir mais malheureusement aucune agence ou professionnel
              du SEO n’est en mesure de vous faire cette promesse. Nous pouvons inclure à nos devis un travail
              d’optimisation SEO de votre futur site, et nous sommes également en mesure de vous proposer différentes
              stratégies de référencement en fonction de vos objectifs et de votre budget.
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="col-6 animated zoomIn">
      <h1 class="text-white text-center"><span
        class="btn-color-secondary">Une audit gratuit de 30 minutes !</span>
      </h1>
      <app-calendly-widget></app-calendly-widget>
    </div>
  </div>
</div>

