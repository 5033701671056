<div class="py-5 btn-primary mb-5">
  <div class="container my-5 py-5 px-lg-5">
    <div class="col-12">
      <h1 class="text-white animated zoomIn"><span>Qu’est-ce que le SEO (ou référencement naturel) ?</span></h1>
      <p class="text-center animated zoomIn">
        Le SEO (Search Engine Optimization), ou référencement naturel, consiste à optimiser les éléments d’un site
        internet pour améliorer son positionnement dans les moteurs de recherche. Le positionnement SEO permet de gagner
        en visibilité et de générer du trafic ciblé. Une agence SEO travaille sur l’ensemble des optimisations possibles
        d’un site : audit SEO, la sémantique, l’indexation des pages, le maillage interne, les backlinks (netlinking) et
        l’amélioration de la popularité du site.</p>
    </div>
  </div>
</div>


<div class="container-xxl py-5">
  <div class="container px-lg-5">
    <div class="flex-column justify-content-center">
      <h1 class="text-center animated zoomIn m-2"><span>Quels sont les avantages du SEO ?</span></h1>
      <div class="row g-4 d-flex justify-content-center">
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center  rounded">
          <span
            class="btn-color-secondary text-center fw-bold"> Bénéficier d’un excellent retour sur investissement </span>
            <p class="text-center">Idéal pour les petites entreprises et startups qui débutent en marketing digital et
              souhaitent établir leur présence en ligne.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center  rounded">
            <span class="btn-color-secondary text-center fw-bold"> Assurer un trafic à long terme </span>
            <p class="text-center">Le référencement naturel s’inscrit dans la durée, les retours peuvent varier entre
              quelques semaines ou plusieurs mois (les résultats ne sont pas immédiats). Pour cette raison, le SEO doit
              s’intégrer dans une démarche projet itérative et incrémentale permettant une réactivité par rapport aux
              premiers résultats. Anticiper et planifier cette problématique est la meilleure façon de s’assurer une
              visibilité pérenne auprès de ses futurs clients. Le référencement naturel Google apporte des résultats qui
              durent longtemps.</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center rounded">
            <span class="btn-color-secondary text-center fw-bold"> Générer du trafic qualifié </span>
            <p class="text-center"> En positionnant votre site sur des requêtes pertinentes et stratégiques, vous vous
              placez sur le chemin de votre prospect en pleine recherche de solutions ou d’informations. Ainsi, on
              considère que le SEO génère un trafic qualifié avec un réel potentiel de conversion par rapport à d’autres
              leviers d’acquisition. Beaucoup de ces derniers génèrent du trafic moins ciblé et moins susceptible de
              finir
              par une conversion (achat, contact téléphonique, prise de rendez-vous, téléchargement…)</p>

          </div>
        </div>
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center rounded">
            <span class="btn-color-secondary text-center fw-bold"> Se rendre visible auprès de votre cible </span>
            <p class="text-center"> Occuper le terrain sur les moteurs de recherche ouvre des gains en visibilité très
              importants, peu importe votre cible, votre activité ou votre secteur d’activité. La recherche
              d’informations
              via les moteurs, est une pratique courante, voire indispensable, avant une prise de décision. En plaçant
              votre site sur la première page de Google sur les requêtes les plus stratégiques, vous vous donnez les
              moyens d’être visibles où cela compte pour votre business.</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

