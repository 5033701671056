@if (teamsItem.length > 0) {
  <div class="container-xxl py-2">
    <div class="container px-lg-5">
      <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
        <h3 class="position-relative d-inline text-primary ps-4">Notre Equipe</h3>
          <h4 class="text-primary mt-2">Rencontrez Les Membres De Notre Equipe</h4>
      </div>
      <div class="d-flex justify-content-center">
        @for (team of teamsItem; track team) {
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="team-item">
              <div class="d-flex">
                <div class="flex-shrink-0 d-flex flex-column align-items-center mt-4 pt-5" style="width: 75px;">
                  <a class="btn btn-square text-primary bg-white my-1" href="{{team.link_linkedin}}"><i
                    class="fab fa-linkedin-in"></i></a>
                </div>
                <img class="img-fluid rounded w-100" ngSrc="{{ team.image }}" width="200" height="200">
              </div>
              <div class="px-4 py-3">
                <h5 class="fw-bold m-0">{{ team.name }}</h5>
                <small>{{ team.company_title }}</small>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}
