import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-guide-seo',
  standalone: true,
  imports: [],
  templateUrl: './guide-seo.component.html',
  styleUrl: './guide-seo.component.css'
})
export class GuideSeoComponent {

}
