<div class="container-xxl position-relative p-0">
  <nav class="navbar navbar-expand-lg navbar-light px-4 px-lg-5 py-3 py-lg-0">
    <a class="navbar-brand p-0">
      <img ngSrc="assets/img/logo.png" width="200" height="100" alt="Logo">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
      <span class="fa fa-bars"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <div class="navbar-nav ms-auto py-0">
        <a routerLink="/" class="nav-item nav-link">Accueil</a>
        <a routerLink="/about" [routerLinkActive]="'active'" class="nav-item nav-link">Qui sommes-nous ?</a>
        <a routerLink="/services" [routerLinkActive]="'active'" class="nav-item nav-link">Nos expertises</a>
        <a routerLink="/career" [routerLinkActive]="'active'" class="nav-item nav-link">Nous rejoindre</a>
      </div>
      <a class="btn bg-primary text-light rounded-pill py-2 px-4 ms-4" (click)="handleContactPage()">
        <i class="fa fa-calendar fa-1x m-lg-2"></i> Prendre Rendez-vous
      </a>
    </div>
  </nav>

</div>
