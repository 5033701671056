import { Component } from '@angular/core';

@Component({
  selector: 'app-termes-conditions',
  standalone: true,
  imports: [],
  templateUrl: './termes-conditions.component.html',
  styleUrl: './termes-conditions.component.css'
})
export class TermesConditionsComponent {

}
