<div class="py-5 btn-primary mb-5">
  <div class="container my-5 py-5 px-lg-5">
    <div class="col-12">
      <h1 class="text-white animated zoomIn"><span>Qu’est-ce que le SEA (ou référencement payant) ?</span></h1>
      <p class="text-center animated zoomIn">
        Le SEA (Search Engine Advertising), ou référencement payant, consiste à optimiser les campagnes d’achat de mots
        clés dans les moteurs de recherche. Le positionnement SEA permet de gagner en visibilité et de générer du trafic
        ciblé. Une agence SEA travaille sur l’ensemble des optimisations possibles des campagnes sur Google Ads et
        Microsoft Advertising : audit de compte, la sémantique, l’indexation des pages, le maillage interne, les
        backlinks (netlinking) et l’amélioration de la popularité du site.</p>
    </div>
  </div>
</div>


<div class="container-xxl py-5">
  <div class="container px-lg-5">
    <div class="flex-column justify-content-center">
      <h1 class="text-center animated zoomIn m-2"><span>Quels sont les avantages du SEA ?</span></h1>
      <div class="row g-4 d-flex justify-content-center">
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center  rounded">
          <span
            class="btn-color-secondary text-center fw-bold"> Assurer un trafic RAPIDEMENT </span>
            <p class="text-center">Le référencement payant s’inscrit dans le court terme et l’instantanéité, les retours
              sont mesuré immédiatement même si l’optimisation des campagnes d’achat de mots clés doivent s’inscrire
              dans une démarche de projet itérative et incrémentale permettant de réagir par rapport aux premiers
              résultats</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center  rounded">
            <span
              class="btn-color-secondary text-center fw-bold">Bénéficier d’un excellent retour sur investissement</span>
            <p class="text-center">Le référencement SEA est une action stratégique à forte rentabilité qu’il faut
              intégrer dans tout plan média sur internet. Ces campagnes d’achat de mots clés permettent de générer du
              trafic qualifié avec un ROI n’ayant peu d’équivalent avec les autres leviers d’acquisition de trafic en
              ligne</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center rounded">
            <span class="btn-color-secondary text-center fw-bold"> Obtenir plus de conversions </span>
            <p class="text-center"> Le travail de l’expert ou de l’agence en référencement payant ne s’arrête pas à
              l’acquisition de trafic. La discipline du SEA recouvre aussi l’optimisation des landing pages et de
              l’expérience utilisateur pour obtenir davantage de conversions. Avec une attention particulière sur
              l’intention de recherche et le contenu de la page de destination, vous mettez toutes les chances de votre
              côté pour réussir à concrétiser vos objectifs</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 wow zoomIn" data-wow-delay="0.1s">
          <div class="pack-item d-flex flex-column justify-content-center rounded">
            <span class="btn-color-secondary text-center fw-bold"> Générer du trafic qualifié </span>
            <p class="text-center">En affichant votre site web sur des recherches qualifiées, vous vous placez sur le
              chemin de votre prospect en pleine recherche de produits, de solutions ou d’informations. Ainsi, on
              considère que le SEA génère un trafic qualifié avec un réel potentiel de conversion par rapport à d’autres
              leviers d’acquisition qui génèrent du trafic moins ciblé</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

