<div class="py-5 btn-primary mb-5">
  <div class="container my-5 py-5 px-lg-5">
    <div class="col-12">
      <h1 class="text-white animated zoomIn"><span>Mentions légales</span></h1>
      <p class="text-center animated zoomIn">
        Le site Webcome&Co.com et ses sous-domaines (ci-après dénommés « Site ») sont édités par Webcome & Co,
        enregistrée au registre du commerce et des sociétés de Rennes sous le numéro "", dont le siège
        social se trouve à Rennes – (Adresse de domiciliation)</p>
    </div>
  </div>
</div>


<div class="container-xxl py-5">
  <div class="container px-lg-5">
    <div class="flex-column justify-content-center">
      <h1 class="animated zoomIn text-center"><span>Propriété intellectuelle</span></h1>
      <div>
        <p>Webcome & Co s’engage à ne pas divulguer à des tiers, les informations que vous nous communiquez. Celles-ci
          sont
          confidentielles. Elles ne seront utilisées par ses services internes que pour le traitement de vos demandes,
          candidature, ou pour l’envoi de la lettre d’information S2M. Conformément à la loi « informatique et libertés
          » du 6 janvier 1978 modifiée en 2004, vous bénéficiez à tout moment d’un droit d’accès et de rectification aux
          informations qui vous concernent. Vous pouvez exercer ce droit en utilisant notre formulaire de contact. Vous
          pouvez également, pour des motifs légitimes, vous opposer au traitement des données vous concernant.</p>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Propriété des Créations Avant Livraison</span>
      <div>
        <p>Tant que l'intégralité des paiements dus en vertu du présent contrat n’a pas été effectuée
          par le Client, l'Agence reste propriétaire exclusif de tous les droits de propriété intellectuelle
          relatifs aux créations réalisées dans le cadre de la prestation.
          Cela inclut, sans s’y limiter :</p>
        <ul>
          <li>
            Les codes sources et codes compilés : Scripts, programmes, et tout code informatique
            développé pour le site.
          </li>
          <li>
            Les éléments graphiques : Logos, bannières, maquettes, design de l’interface utilisateur
            (UI), et autres créations visuelles.
          </li>
          <li>
            Les contenus textuels et éditoriaux : Textes, slogans, et autres éléments rédactionnels
            fournis par l'Agence.
          </li>
          <li>
            Les contenus multimédias : Vidéos, animations, illustrations, et autres éléments
            audiovisuels créés par l'Agence.
          </li>
          <li>
            La structure et l'architecture du site : La mise en page, l'organisation des contenus, ainsi
            que la navigation interne du site.
          </li>
          <br>
          Aucun des éléments précités ne peut être utilisé, copié, modifié ou distribué par le Client
          sans l’autorisation préalable écrite de l'Agence.
        </ul>
      </div>
      <span
        class="animated zoomIn fw-bold text-dark">Cession des Droits de Propriété Intellectuelle Après Livraison</span>
      <div>
        <p>À la livraison du site internet et après le paiement intégral de la prestation par le Client,
          l'Agence cède au Client les droits de propriété intellectuelle sur les éléments créés
          spécifiquement pour le site internet, sous les conditions suivantes :</p>
        <ul>
          <li>
            Portée de la cession : La cession comprend le droit d'utiliser, reproduire, représenter,
            modifier, adapter, et exploiter commercialement le site internet et ses éléments pour les
            besoins propres du Client.
          </li>
          <li>
            Durée : La cession est consentie pour toute la durée de protection légale des droits de
            propriété intellectuelle, telle que définie par la législation en vigueur.
          </li>
          <li>
            Territoire : La cession est consentie pour le monde entier, sans limitation géographique
          </li>
          <li>
            Nature de la cession : La cession des droits est exclusive, ce qui signifie que l'Agence ne
            conserve aucun droit d'exploitation sur les éléments cédés, sauf accord spécifique entre
            les parties.
          </li>
        </ul>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Exceptions à la Cession de Droits</span>
      <div>
        <p>Certaines créations ou éléments utilisés pour le développement du site internet peuvent
          rester la propriété exclusive de l'Agence ou de tiers, notamment :</p>
        <ul>
          <li>
            Éléments préexistants : Les outils, méthodes, frameworks, bibliothèques de code, et
            autres éléments préexistants utilisés par l'Agence pour le développement du site.
            L'Agence conserve tous les droits sur ces éléments, mais concède au Client une licence
            d'utilisation non exclusive, non transférable et sans redevance, strictement limitée à
            l'exploitation du site internet.
          </li>
          <li>
            Éléments sous licence : Les éléments graphiques, photographies, vidéos, polices de
            caractères, et autres contenus sous licence obtenus par l'Agence auprès de tiers pour le
            compte du Client. L'utilisation de ces éléments par le Client est soumise aux termes de la
            licence accordée par les fournisseurs respectifs.
          </li>
          <li>
            Plugins, extensions, et logiciels tiers : Les plugins, extensions, et autres logiciels tiers
            intégrés au site internet peuvent être soumis à des licences spécifiques que le Client
            devra respecter. L'Agence ne cède aucun droit de propriété sur ces éléments, mais
            s'engage à informer le Client des conditions d’utilisation applicables.
          </li>
        </ul>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Droits Moraux</span>
      <div>
        <p>L'Agence conserve le droit moral sur ses créations, conformément à l'article L.121-1 du Code
          de la propriété intellectuelle. Cela inclut notamment :</p>
        <ul>
          <li>
            Le droit à la paternité : Le droit pour l'Agence d’être reconnue comme l'auteur des
            créations, même après la cession des droits de propriété intellectuelle.
          </li>
          <li>
            Le droit au respect de l'œuvre : Le droit pour l'Agence de s’opposer à toute modification,
            dénaturation ou altération de ses créations qui pourrait porter atteinte à son honneur ou
            à sa réputation.
          </li>
        </ul>
        <br>
        En conséquence, le Client s'engage à ne pas altérer les créations de manière préjudiciable et
        à respecter l'intégrité des œuvres fournies.
      </div>
      <span class="animated zoomIn fw-bold text-dark">Mention de l'Agence</span>
      <div>
        <p>Sauf demande expresse contraire du Client, l'Agence se réserve le droit d’inclure sur le site
          internet une mention discrète indiquant qu'elle en est le créateur (par exemple, un lien en
          bas de page mentionnant "Site réalisé par Webcome & Co"). Cette mention pourra inclure un
          lien vers le site internet de l'Agence.
          L'Agence se réserve également le droit de présenter le site internet dans son portfolio, sur
          son site web, et dans toute autre communication marketing, à des fins de démonstration de
          son savoir-faire</p>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Indemnisation et Garantie</span>
      <div>
        <p>Le Client garantit à l'Agence que tous les éléments qu’il lui fournit pour intégration dans le
          site internet (textes, images, vidéos, logos, etc.) sont libres de droits ou que le Client a
          obtenu les autorisations nécessaires pour leur utilisation. Le Client s'engage à indemniser
          l'Agence pour toute réclamation ou action en justice liée à la violation des droits de tiers
          résultant de l'utilisation des éléments fournis par le Client.
          L'Agence, quant à elle, garantit que les éléments qu’elle fournit et cède au Client sont
          originaux ou utilisés conformément aux droits d'utilisation obtenus auprès de tiers. L'Agence
          s'engage à indemniser le Client pour toute réclamation ou action en justice liée à la violation
          des droits de propriété intellectuelle d’un tiers par les créations réalisées dans le cadre de la
          prestation.
        </p>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Confidentialité</span>
      <div>
        <p>L'Agence s'engage à ne pas divulguer les informations confidentielles du Client, obtenues
          dans le cadre de l'exécution du présent contrat, à des tiers sans l'accord préalable du Client
        </p>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Garantie et Maintenance</span>
      <div>
        <p>L'Agence garantit que le site internet fonctionnera conformément aux spécifications du
          cahier des charges pendant une période de [nombre de mois] mois après sa mise en ligne.
          Toute anomalie signalée pendant cette période sera corrigée sans frais supplémentaires.
          Au-delà de cette période, un contrat de maintenance pourra être mis en place moyennant
          des conditions financières à définir.
        </p>
      </div>
    </div>
  </div>
</div>

