<div class="py-5 btn-primary mb-5">
  <div class="container my-5 py-5 px-lg-5">
    <div class="col-12">
      <h1 class="text-white animated zoomIn"><span>Termes & conditions </span></h1>
      <p class="text-center animated zoomIn">
        Le site Webcome&Co.com et ses sous-domaines (ci-après dénommés « Site ») sont édités par Webcome & Co,
        enregistrée au registre du commerce et des sociétés de Rennes sous le numéro "", dont le siège
        social se trouve à Rennes – (Adresse de domiciliation)</p>
    </div>
  </div>
</div>


<div class="container-xxl py-5">
  <div class="container px-lg-5">
    <div class="flex-column justify-content-center">
      <h1 class="animated zoomIn text-center"><span>Termes && Conditions</span></h1>
      <div>
        <p>.</p>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Délais d’Exécution</span>
      <div>
        <p>L'Agence s'engage à respecter le calendrier suivant :</p>
        <ul>
          <li>
            Définition du cahier des charges : Lors du deuxième rendez-vous avec le client.
            Présentation de la maquette : [nombre de jours] jours après validation du cahier des
            charges.

          </li>
          <li>
            Développement du site : [nombre de jours] jours après validation de la maquette.
          </li>
          <li>
            Mise en ligne du site : [nombre de jours] jours après validation finale par le Client
          </li>
          <br>
          Tout retard dû à des modifications demandées par le Client ou à une absence de réponse
          dans un délai raisonnable (3 jours ouvrés) entraînera une révision des délais d'exécution.
        </ul>
      </div>
      <span
        class="animated zoomIn fw-bold text-dark">Conditions Financières</span>
      <div>
        <p>Le montant total de la prestation s’élève à [montant en euros] € HT, réparti comme suit :
        </p>
        <ul>
          <li>
            50 % à la signature du contrat.
          </li>
          <li>
            50 % à la livraison finale et mise en ligne du site.
          </li>
          <br>
          Les paiements devront être effectués sous 5 jours après réception de chaque facture.
        </ul>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Obligations du Client</span>
      <div>
        <p>Le Client s'engage à respecter les obligations suivantes afin de permettre à l'Agence de
          réaliser la prestation dans les meilleures conditions et conformément aux délais convenus :</p>
        <ul>
          <li>
            Contenu rédactionnel : Textes, descriptions de produits ou services, articles, mentions
            légales, politiques de confidentialité, etc.
          </li>
          <li>
            Éléments graphiques : Logos, images, photographies, vidéos, et tout autre visuel à
            intégrer au site.
          </li>
          <li>
            Informations techniques : Accès aux serveurs d'hébergement, bases de données, et tout
            autre élément technique nécessaire.
          </li>
          <li>
            Références spécifiques : Toute information ou documentation spécifique liée à l'identité
            visuelle ou au branding de l'entreprise (polices de caractères, couleurs, chartes
            graphiques, etc.).
          </li>
          <br>
          Ces éléments doivent être fournis dans les formats et selon les spécifications techniques
          demandés par l'Agence, dans un délai de 10 jours à compter de la signature du contrat. Tout
          retard ou manquement dans la fourniture de ces éléments pourra entraîner un décalage des
          délais de livraison, sans que l'Agence puisse en être tenue responsable
        </ul>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Validation des Livrables</span>
      <div>
        <p>Le Client s'engage à examiner et valider les livrables fournis par l'Agence, tels que : </p>
        <ul>
          <li>
            Cahier des charges : Le Client doit valider le cahier des charges finalisé avant le début de
            la conception graphique.
          </li>
          <li>
            Maquettes : Les maquettes du site internet doivent être validées par le Client avant le
            passage à la phase de développement.
          </li>
          <li>
            Livrables intermédiaires : Tout autre livrable intermédiaire, comme les prototypes
            fonctionnels ou les versions bêta du site, doit être validé dans un délai de 7 jours après
            réception.
          </li>
        </ul>
        <br>
        L'absence de validation dans les délais impartis sera considérée comme une acceptation
        tacite, permettant à l'Agence de poursuivre l'exécution du proje
      </div>
      <span class="animated zoomIn fw-bold text-dark">Collaboration et Communication</span>
      <div>
        <p>Le Client s'engage à :</p>
        <ul>
          <li>
            Désigner un interlocuteur unique : Cette personne sera en charge de la communication
            avec l'Agence, afin de faciliter le suivi du projet et d'éviter toute confusion ou perte
            d'information.
          </li>
          <li>
            Participer aux réunions : Le Client s'engage à être disponible pour les réunions de suivi
            de projet, qu'elles soient en personne, par téléphone ou par visioconférence, selon les
            modalités définies ensemble au préalable.
          </li>
          <li>
            Répondre aux demandes d'informations : Le Client s'engage à répondre aux questions et
            demandes de l'Agence dans un délai raisonnable de 7 jours ouvrés. Tout retard de
            réponse pourra entraîner un ajustement des délais du projet.
          </li>
        </ul>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Respect des Conditions de Paiemen</span>
      <div>
        <p>Le Client s'engage à respecter strictement les conditions de paiement stipulées à l'article 4
          du présent contrat. Tout retard de paiement de la part du Client pourra entraîner :
        </p>
        <ul>
          <li>
            La suspension des travaux : L'Agence se réserve le droit de suspendre l'exécution des
            services en cas de non-paiement.
          </li>
          <li>
            Des pénalités de retard : Des pénalités pourront être appliquées, calculées sur la base
            d’un taux d'intérêt de 5 % du montant total dû, par jour de retard.
          </li>
          <li>
            La résiliation du contrat : En cas de non-paiement persistant, l'Agence pourra résilier le
            contrat conformément à l'article 9.
          </li>
        </ul>
      </div>
      <span class="animated zoomIn fw-bold text-dark"> Conformité Légale et Réglementaire</span>
      <div>
        <p>Le Client garantit que tous les éléments fournis à l'Agence (textes, images, vidéos, etc.)
          respectent les lois en vigueur, notamment en matière de propriété intellectuelle, de
          protection des données personnelles, et de droits d'auteur. Le Client s'engage à indemniser
          l'Agence en cas de recours ou de plainte liée à la non-conformité des éléments fournis.
        </p>
      </div>
      <span class="animated zoomIn fw-bold text-dark">Utilisation du Site Internet</span>
      <div>
        <p>Après la livraison et la mise en ligne du site internet, le Client s'engage à utiliser le site
          conformément aux bonnes pratiques d'internet et dans le respect des lois en vigueur. Toute
          modification du site réalisée par le Client ou un tiers sans l’accord de l'Agence pourra
          entraîner la nullité des garanties fournies par l'Agence, notamment en ce qui concerne la
          maintenance et le support technique.
        </p>
      </div>
    </div>
  </div>
</div>

