<div class="py-5 btn-primary mb-5">
  <div class="container my-5 py-5 px-lg-5">
    <div class="align-items-center">
      <div class="col-12 text-center">
        <h1 class="text-white animated zoomIn mt-5">Découvrez une équipe <br> qui aime les challenges</h1>
        <p class="mt-5 text-center animated zoomIn text-white">Spécialisés dans le design de sites web percutants, nous voyons chaque
          projet comme une occasion d'innover et de dépasser les attentes. Chez
          Webcome & Co, nous relevons les défis avec passion pour transformer votre
          présence en ligne, convertir vos ventes, acquérir de nouveaux clients et
          atteindre vos objectifs les plus ambitieux. Visibilité, performance et
          créativité sont au cœur de tout ce que nous faisons.Nous plaçons la visibilité, la performance et la créativité au centre de notre
          approche pour vous garantir une différence marquée face à la concurrence.
        </p>
      </div>
    </div>
  </div>
</div>

<div class="container-xxl py-5">
  <div class="container px-lg-5">
    <div class="section-title position-relative text-center mb-5 pb-2 wow fadeInUp" data-wow-delay="0.1s">
      <h6 class="position-relative d-inline text-primary ps-4">Notre Façon De Travailler</h6>
    </div>
    <div class="row g-4">
      <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.1s">
        <div class="d-flex flex-column justify-content-center text-center rounded">
          <h4 class="btn-color-secondary"> Écoute & Compréhension </h4>
          <p class="m-2 text-center">Tout commence par vous ! Nous prenons le temps de comprendre vos besoins, vos
            objectifs d'affaires, et votre vision. Chaque projet débute par une phase d’analyse approfondie de votre
            secteur, de votre public cible et de vos concurrents. </p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
        <div class="d-flex flex-column justify-content-center text-center rounded">
          <h4 class="btn-color-secondary"> Stratégie Personnalisée </h4>
          <p class="m-2 text-center">Sur la base de nos échanges et de nos recherches, nous concevons une stratégie
            marketing sur mesure qui répond à vos objectifs. Que ce soit pour augmenter votre visibilité, générer des
            leads ou renforcer votre image de marque, nous mettons en place un plan d'action adapté, mêlant les canaux
            et les leviers les plus pertinents.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
        <div class="d-flex flex-column justify-content-center text-center rounded">
          <h4 class="btn-color-secondary"> Suivi & Optimisation Continue </h4>
          <p class="m-2 text-center">Le lancement d’une campagne n’est que le début. Nous suivons en temps réel les
            performances de chaque initiative et ajustons nos actions en fonction des résultats. Notre priorité est
            d'optimiser continuellement pour maximiser votre retour sur investissement.</p>
        </div>
      </div>
    </div>
  </div>
</div>
